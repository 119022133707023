<template>
  <base-section id="how-security">
    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('title')"
      :text="$i18n.t('subtitle')"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="(card, i) in fullCards"
          :key="i"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('s2.title')"
      :text="$i18n.t('s2.text')"
    />
    <div style="background-color: #00bcd4;">
      <base-section-heading
        class="pb-8"
        color="white"
        :title="$i18n.t('s0.title')"
        :text="$i18n.t('s0.text')"
        dark
      />
    </div>

    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('s1.title')"
      :text="$i18n.t('s1.text')"
    />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          icon: 'fas fa-qrcode',
          callout: '1',
          title: ' ',
        },
        {
          icon: 'fas fa-mobile',
          callout: '2',
          title: ' ',
        },
        {
          icon: 'fas fa-spray-can',
          callout: '3',
          title: ' ',
        },
      ],
    }),
    computed: {
      fullCards () {
        return this.cards.map((card, i) => {
          card.text = this.$i18n.t(`t${i}.text`)
          return card
        })
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"last": "SGD Group provides specialised technical personnel, its own facilities and an analytical laboratory, in order to ensure that the product has been diluted properly, by testing its concentration through reactive strips in biodegradable polyester, as well as to guarantee an accurate sanitisation of the surfaces.",
		"subtitle": "All of the sanitification activities take place following the instructions and protocols provided by SGD group. We are committed to ensuring maximum professionalism and seriousness in carrying out each intervention, for the safety of the end user.",
		"t0": {
			"text": "Beach loungers and sun umbrellas are sanitised using a disinfectant product with a low environmental impact."
		},
		"t1": {
			"text": "Once the surfaces are completely dry, beach loungers and sun umbrellas are ready to use."
		},
		"t2": {
			"text": "Everything is now completely sanitised!"
		},
		"title": "How we operate"
	},
	"it": {
		"subtitle": "Solo grazie a SanyBeach i tuoi ospiti potranno sapere quando è stato sanificato il loro lettino e ombrellone l’ultima volta, con data e ora specifici, semplicemente scansionando il codice QR sull’ombrellone! Scopri di seguito come funziona il servizio:",
		"s0": {
      "title": "Non hai tempo di farlo?",
      "text": "Ti offriamo la possibilità di usufruire del nostro servizio di avviamento al progetto: ci occupiamo noi di applicare ed associare i codici QR del tuo stabilimento per permetterti di utilizzare il più rapidamente possibile il servizio di tracciamento delle sanificazioni."
    },
    "s1": {
      "title": "Verifica sanificazioni",
      "text": "Ti offriamo la possibilità di usufruire del nostro servizio di avviamento al progetto: ci occupiamo noi di applicare ed associare i codici QR del tuo stabilimento per permetterti di utilizzare il più rapidamente possibile il servizio di tracciamento delle sanificazioni."
    },
    "s2": {
      "title": "Consiglio",
      "text": "Attendere l'evaporazione del prodotto prima di permettere l'utilizzo del lettino"
    },
    "t0": {
			"text": "<b>Applica i codici QR</b> su ogni ombrellone, lettino, sull’area giochi o sui servizi igienici - decidi tu dove mostrare il servizio di sanificazione con data e ora e promuovere i tuoi servizi!"
		},
		"t1": {
			"text": "La prima volta <b>associa i codici QR</b> alle postazioni corrette: scarica l’app SanyBeach, premi ASSOCIA e seleziona il lotto, scansiona i codici QR affissi con la fotocamera e ripeti per ogni lotto dello stabilimento: è semplice e veloce!"
		},
		"t2": {
			"text": "<b>Sanifica la postazione e scansiona il codice QR per registrare l’intervento per l’intero lotto</b>: verrà mostrata data e ora della sanificazione all’ospite della postazione assieme ai <b>servizi del tuo stabilimento!</b>"
		},
		"title": "Tracciamento sanificazioni e avviamento al servizio"
	}
}
</i18n>
